import type { RenderLoader, CreateStartOptions } from '../types'

export interface Options extends CreateStartOptions {
  mediaQuery: string
}

export default function calculator(renderLoader: RenderLoader, mountPointId: string, options: Options) {
  const start = async () => {
    if (!window.matchMedia(options.mediaQuery).matches) {
      return
    }

    window.removeEventListener('resize', start)

    await (await options.lazyStoreManagerLoader()).initializing

    const renderer = renderLoader()

    const mountPoint = document.getElementById(mountPointId) as HTMLElement

    ;(await renderer).render(mountPoint)
  }

  window.addEventListener('resize', start)

  start();

  const hideByMediaQuery = () => {
    const mountPoint = document.getElementById(mountPointId) as HTMLElement

    if (window.matchMedia(options.mediaQuery).matches) {
      mountPoint.classList.remove('hidden')
    } else {
      mountPoint.classList.add('hidden')
    }
  }

  window.addEventListener('resize', hideByMediaQuery)
}